'use-strict';

/**
 * Gets the url parameters as object
 * @param url
 * @returns {{}}
 */
export var getUrlParameters = function (url) {
    // get query string from url (optional) or window
    var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

    // we'll store the parameters here
    var obj = {};

    // if query string exists
    if (queryString) {

        // stuff after # is not part of query string, so get rid of it
        queryString = queryString.split('#')[0];

        // split our query string into its component parts
        var arr = queryString.split('&');

        for (var i=0; i<arr.length; i++) {
            // separate the keys and the values
            var a = arr[i].split('=');

            // in case params look like: list[]=thing1&list[]=thing2
            var paramNum = undefined;
            var paramName = a[0].replace(/\[\d*\]/, function(v) {
                paramNum = v.slice(1,-1);
                return '';
            });

            // set parameter value (use 'true' if empty)
            var paramValue = typeof(a[1]) === 'undefined' ? true : a[1];

            // (optional) keep case consistent
            paramName = paramName.toLowerCase();
            paramValue = paramValue.toLowerCase();

            // if parameter name already exists
            if (obj[paramName]) {
                // convert value to array (if still string)
                if (typeof obj[paramName] === 'string') {
                    obj[paramName] = [obj[paramName]];
                }
                // if no array index number specified...
                if (typeof paramNum === 'undefined') {
                    // put the value on the end of the array
                    obj[paramName].push(paramValue);
                }
                // if array index number specified...
                else {
                    // put the value at that index number
                    obj[paramName][paramNum] = paramValue;
                }
            }
            // if param name doesn't exist yet, set it
            else {
                obj[paramName] = paramValue;
            }
        }
    }

    return obj;
};

/**
 * Add url parameters and update if the parameterName exists
 * @param url
 * @param parameterName
 * @param parameterValue
 * @returns {string}
 */
export var updateQueryStringParameter = function (url, parameterName, parameterValue) {
    var newUrl = url;
    var regEx = new RegExp('([?&])' + parameterName + '=.*?(&|$)', 'i');
    var separator = url.indexOf('?') !== -1 ? '&' : '?';
    if (url.match(regEx)) {
        newUrl = url.replace(regEx, '$1' + parameterName + '=' + parameterValue + '$2');
    }
    else {
        newUrl = url + separator + parameterName + '=' + parameterValue;
    }

    return newUrl;
};

/**
 * Removes an empty url parameter
 * @param url
 * @param parameter
 * @returns {*}
 */
export var removeEmptyParameter = function (url, parameter) {
    var newUrl = url;
    //prefer to use l.search if you have a location/link object
    var urlParts = url.split('?');
    if (urlParts.length >= 2) {
        var prefix = encodeURIComponent(parameter) + '=';
        var parts = urlParts[1].split(/[&;]/g);

        //reverse iteration as may be destructive
        for (var i= parts.length; i-- > 0;) {
            //idiom for string.startsWith
            if (parts[i].lastIndexOf(prefix, 0) !== -1) {
                parts.splice(i, 1);
            }
        }

        newUrl = urlParts[0] + (parts.length > 0 ? '?' + parts.join('&') : '');
    }

    return newUrl;
};

export var updatePushUrl = function (filters) {
    let query = '';
    for (const prop in filters) {
        // don't add day if it's the first one available
        if (filters[prop].key === 'tag' && filters[prop].index === 0) {
            continue;
        }

        // check if filter has any urlnames set
        if (filters[prop].urlname !== '') {
            if (query.indexOf('?') !== -1) {
                query += '&';
            } else {
                query += '?';
            }
            query += `${filters[prop].key}=${filters[prop].urlname}`;
        }
    }

    // set new url and update params object
    const newurl = `${window.location.protocol}//${window.location.host + window.location.pathname + query}`;
    return window.history.pushState({path:newurl},'',newurl);
};
