'use-strict';

import {Cookies, smoothscroll} from './vendor';
import {querySelect, queryAll, delegate} from './helpers/dom';
import {modalPlayer} from './components/modal-player';
import {modal} from './components/modal';
import {GridFilter} from './components/filter';
import {initPhotoSwipeFromDOM} from './components/lightbox';
import {share} from './components/share';

smoothscroll.polyfill();

// matches polyfill
if (!Element.prototype.matches) {
    Element.prototype.matches =
        Element.prototype.matchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        Element.prototype.webkitMatchesSelector ||
        function (s) {
            var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                i = matches.length;
            while (--i >= 0 && matches.item(i) !== this) {
            }
            return i > -1;
        };
}

// from:https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
(function (arr) {
    arr.forEach(function (item) {
        if (item.hasOwnProperty('remove')) {
            return;
        }
        Object.defineProperty(item, 'remove', {
            configurable: true,
            enumerable: true,
            writable: true,
            value: function remove() {
                this.parentNode.removeChild(this);
            }
        });
    });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);

const desktopMedia = window.matchMedia('(min-width: 992px)');

// Handle Desktop Navigation
const menu = querySelect('.show-menu');
const navWrapper = querySelect('.nav-wrapper');
const navContent = querySelect('.nav-content');
const mobileNavWrapper = querySelect('.mobile-nav-wrapper');
const closeMobileNavBtn = querySelect('.close-mobile-nav');
const menuActiveClass = 'menu-desktop-active';
const mobileMenuActiveClass = 'menu-mobile-active';

// Handle desktop nav opening, closing and mobile nav opening
menu.addEventListener('click', function (event) {
    // if in desktop view
    if (desktopMedia.matches) {
        // if menu is open, close it
        if (navWrapper.classList.contains(menuActiveClass)) {
            navContent.classList.add('animating');
            navWrapper.classList.remove(menuActiveClass);
            navWrapper.setAttribute('aria-hidden', 'true');
            menu.classList.remove('transform-to-cross');
        } else {
            navWrapper.setAttribute('aria-hidden', 'false');
            navWrapper.classList.add(menuActiveClass);
            menu.classList.add('transform-to-cross');
        }
    } else {
        mobileNavWrapper.setAttribute('aria-hidden', 'false');
        document.documentElement.classList.add('noscroll');
        mobileNavWrapper.classList.add(mobileMenuActiveClass);
    }
}, {passive: true});

navContent.addEventListener('transitionend', function (event) {
    this.classList.remove('animating');
}, false);

// Close navigation on backdrop click
navWrapper.addEventListener('click', function (event) {
    if (event.target === navWrapper) {
        navWrapper.classList.remove(menuActiveClass);
        navContent.classList.add('animating');
        navWrapper.setAttribute('aria-hidden', 'true');
        menu.classList.remove('transform-to-cross');
    }
}, {passive: true});

// Close mobile nav via close button
closeMobileNavBtn.addEventListener('click', function (event) {
    document.documentElement.classList.remove('noscroll');
    mobileNavWrapper.classList.remove(mobileMenuActiveClass);
    mobileNavWrapper.setAttribute('aria-hidden', 'true');
}, {passive: true});

const mobileNavDropdown = queryAll('.nav-mobile-content .has-children > a');
for (var a = 0; a < mobileNavDropdown.length; a++) {
    mobileNavDropdown[a].addEventListener('click', function (event) {
        event.preventDefault();

        this.classList.toggle('subnav-link-active');
    });
}

// Add to movielist modal
const moviesRow = queryAll('.movies-row');
const MovieListModal = modal('.movie-list-modal');
const movieAddedCssClass = 'movie-item-added';
const isMovieList = querySelect('#movie-list') !== null;

function removeMovieFromMovielist(element) {
    return element !== this.dataset.movie;
}

let updatedList; // movieid list
const RecommendationModal = modal('.recommendation-modal');
for (var mr = 0; mr < moviesRow.length; mr++) {
    // Handle movielist
    delegate(moviesRow[mr], 'click', '.js-add-movielist', function () {
        const currentList = Cookies.get('movielist');

        // if clicked on an already saved movie
        if (this.classList.contains(movieAddedCssClass)) {
            // remove clicked from current list
            const movieIds = currentList.split('+').filter(removeMovieFromMovielist, this);

            // if no more movies saved, set empty
            if (movieIds.length === 0) {
                updatedList = '';
            } else if (movieIds.length === 1) {
                // if only one use first index
                updatedList = movieIds[0];
            } else {
                // else create plus seperated string
                updatedList = movieIds.join('+');
            }

            Cookies.set('movielist', updatedList);

            // if we're on the movie list page
            if (isMovieList) {
                // remove movie item from DOM
                querySelect('.movie-item[data-movie-urlname="' + this.getAttribute('data-movie') + '"]').remove();
            } else {
                this.classList.remove(movieAddedCssClass);
            }

            addMoviesToUrl(updatedList);
        } else {
            updatedList = '';

            // if cookie is set and has a value, add a comma
            if (currentList !== undefined && currentList.length > 0) {
                updatedList += currentList + '+';
            }
            updatedList += this.getAttribute('data-movie');
            Cookies.set('movielist', updatedList);
            this.classList.add(movieAddedCssClass);
            MovieListModal.open();
            setTimeout(function () {
                MovieListModal.close();
            }, 5000);
            addMoviesToUrl(updatedList);
        }
    }, {passive: true});

    // Show recommendations modal
    delegate(moviesRow[mr], 'click', '.js-view-recommendation', function () {
        var review = this.dataset.reviews;
        var reviewHtml = JSON.parse(review);
        querySelect('.movie-recommendation-list').innerHTML = reviewHtml.html;
        RecommendationModal.open();
    }, {passive: true});
}

const defaultMovielistUrl = querySelect('.movie-list-url[data-defaulturl]') !== null ? querySelect('.movie-list-url[data-defaulturl]').getAttribute('data-defaulturl') : '';
const mailShare = querySelect('.share-to-email');

/**
 * Add current movielist to movie-list url
 * @param movies
 */
function addMoviesToUrl(movies) {
    const movieListUrl = queryAll('.movie-list-url');
    const movieList = querySelect('#movie-list');
    var parameterName = 'filme';
    for (var mlu = 0; mlu < movieListUrl.length; mlu++) {
        movieListUrl[mlu].href = defaultMovielistUrl + '?filme=' + movies;
    }

    if (movieList !== null) {
        var url = window.location.href;
        var newUrl = url;
        var regEx = new RegExp('([?&])' + parameterName + '=.*?(&|$)', 'i');
        var separator = url.indexOf('?') !== -1 ? '&' : '?';
        if (url.match(regEx)) {
            newUrl = url.replace(regEx, '$1' + parameterName + '=' + movies + '$2');
        } else {
            newUrl = url + separator + parameterName + '=' + movies;
        }
        if (!movies.length) {
            newUrl = url.split('?')[0];
        }

        if (mailShare !== null) {
            const mailSubject = mailShare.getAttribute('data-subject');
            const mailBody = mailShare.getAttribute('data-body');
            const mailText = `mailto:?subject=${mailSubject}&body=${mailBody}%0D`;
            mailShare.setAttribute('href', mailText + newUrl);
        }

        window.history.replaceState({path: newUrl}, '', newUrl);
    }
}

const initialMovieList = Cookies.get('movielist');
if (initialMovieList !== undefined && initialMovieList.length > 0) {
    addMoviesToUrl(initialMovieList);
}

// Show phone ticket order modal
const moviePhoneOrder = queryAll('.js-phone-order');
const TicketModal = modal('.movie-ticket-modal');
const cinemaContent = querySelect('.cinema-content');
const cinemaFrame = querySelect('.cinema-content .ticket-url');
const cinemaFrameMobile = querySelect('.cinema-content .ticket-url-mobile');

for (var k = 0; k < moviePhoneOrder.length; k++) {
    moviePhoneOrder[k].addEventListener('click', function (event) {
        event.preventDefault();
        const cinema = this.dataset.cinema;
        const ticketUrl = this.dataset.ticketurl;
        const ticketUrlMobile = this.dataset.ticketurlmobile;
        cinemaContent.classList.add('visible');

        if (ticketUrl && ticketUrlMobile) {
            querySelect('.movie-ticket-modal > .modal-box').classList.add('big');
            cinemaFrame.src = ticketUrl;
            cinemaFrameMobile.src = ticketUrlMobile;
            cinemaFrame.style.height = '';
            /*cinemaFrame.onload = function () {
                const iframeDocument = this.contentWindow.document;
                const iframHeight = iframeDocument.body.scrollHeight;
                cinemaFrame.style.height = iframHeight > 500 ? 500+'px' : iframHeight+'px';
                cinemaFrameMobile.height = iframHeight > 500 ? 500 : iframHeight;
                cinemaFrameMobile.parentElement.style.height = iframHeight > 500 ? 500+'px' : iframHeight+'px';
            };*/
            if (querySelect('.cinema-information.visible')) {
                querySelect('.cinema-information.visible').classList.remove('visible');
            }
            if (cinema) {
                querySelect('.cinema-information[data-cinema="' + cinema + '"]').classList.add('visible');
            }
        } else {
            cinemaFrame.src = '';
            cinemaFrame.style.height = 0;
            if (querySelect('.cinema-information.visible')) {
                querySelect('.cinema-information.visible').classList.remove('visible');
            }
            if (cinema) {
                querySelect('.cinema-information[data-cinema="' + cinema + '"]').classList.add('visible');
            }
        }

        TicketModal.open();
    });
}

// Show more
const readMore = querySelect('.read-more');
if (readMore !== null) {
    const readMoreText = readMore.innerText;
    const readLessText = readMore.getAttribute('data-less');
    const readMoreEl = querySelect('.read-more-text');
    readMore.addEventListener('click', function (e) {
        e.preventDefault();

        if (readMoreEl.classList.contains('active')) {
            readMore.innerText = readMoreText;
            readMoreEl.style.height = 0;
        } else {
            readMoreEl.style.height = 'auto';
            const height = readMoreEl.getBoundingClientRect().height;
            requestAnimationFrame(() => {
                readMoreEl.style.height = 0;
            });
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    readMoreEl.style.height = height + 'px';
                    readMore.innerText = readLessText;
                });
            });
        }

        readMoreEl.classList.toggle('active');
    });
}

const scrollToTop = querySelect('.scroll-top');
if (scrollToTop !== null) {
    scrollToTop.addEventListener('click', function () {
        window.scroll({top: 0, left: 0, behavior: 'smooth'});
    }, {passive: true});
}

const alertClose = querySelect('.alert .close');
if (alertClose !== null) {
    alertClose.addEventListener('click', function () {
        this.parentElement.remove();
    });
}

// remove the danger error class on input fields
function removeDanger() {
    if (this.value.length) {
        let alertField;

        if (this.classList.contains('ticketfield') && parseInt(this.value) > 0) {
            const otherTicketFields = queryAll('.ticket-fields .has-danger');
            // remove class from all ticket input fields
            for (var i = 0; i < otherTicketFields.length; i++) {
                otherTicketFields[i].classList.remove('has-danger');
            }

            alertField = querySelect('.alert-danger label[for="ticketfield"]');
        } else {
            let parent = this.parentElement;
            if (parent.classList.contains('has-danger')) {
                parent.classList.remove('has-danger');
            } else {
                parent.parentElement.classList.remove('has-danger');
            }
            alertField = querySelect(`.alert-danger label[for="${this.name}"]`);
        }

        this.removeEventListener('keyup', removeDanger);
        this.removeEventListener('change', removeDanger);

        if (alertField !== null) {
            // remove element from error box
            alertField.remove();

            // remove error box if there are no more error fields
            if (queryAll('.alert-danger label').length === 0) {
                querySelect('.alert-danger').remove();
            }
        }
    }
}

const errorFields = queryAll('.has-danger input, .has-danger textarea');
for (var ef = 0; ef < errorFields.length; ef++) {
    errorFields[ef].addEventListener('keyup', removeDanger);
    errorFields[ef].addEventListener('change', removeDanger);
}

if (querySelect('.movie-slider') !== null) {
    import('./components/slider')
        .then((slider) => slider.default())
        .catch((err) => {
            console.warn('Chunk slider.js loading failed', err);
        });

}

if (querySelect('.promo-box-content') !== null) {

    import('./components/slider-banner')
        .then((slider) => slider.default())
        .catch((err) => {
            console.warn('Chunk slider-banner.js loading failed', err);
        });
}

modalPlayer();
GridFilter();
initPhotoSwipeFromDOM('.js-full-screen-icon');
share();
