'use-strict';

import {Shuffle, imagesLoaded, isEqual, difference} from '../vendor';
import {querySelect, queryAll, getSelectedFromNodeList, updateFilterListFromSelect} from '../helpers/dom';
import {getUrlParameters, updatePushUrl} from '../helpers/url';
import {replaceSpecialChars, getActiveFilters, createFilterUrl, intersectedFilter} from '../helpers/getFilters';

function getMaxOfArray(numArray) {
    return Math.max.apply(null, numArray);
}

export var GridFilter = function () {
    // don't instantiate grid when no filters are available
    if (querySelect('.filter') === null) return;

    const desktopXlMQ = window.matchMedia('(min-width: 1200px)');
    const desktopMQ = window.matchMedia('(max-width: 992px) and (max-width: 1199px)');
    const tabletMQ = window.matchMedia('(min-width: 768px) and (max-width: 991px)');
    const mobileMQ = window.matchMedia('(max-width: 767px)');
    const movieItemSmall = querySelect('.movie-item-small');
    let shuffleInstances = [];
    let initialOrPushParemeters = getUrlParameters(); // used to compare old and new url params
    const shuffleOptionsDefault = {
        itemSelector: movieItemSmall !== null ? '.movie-item-small' : '.movie-item',
        gutterWidth: 30,
        sizer: movieItemSmall !== null ? '.movie-item-small' : '.movie-item'
    };

    // open close filter menu
    const filter = queryAll('.filter-header');
    for (let l = 0; l < filter.length; l++) {
        filter[l].addEventListener('click', function () {
            const filterContent = this.nextElementSibling;
            // if not set or set to false isHidden = true else false
            const isHidden = filterContent.getAttribute('aria-hidden') === 'false' ? false : true;

            if (isHidden === true) {
                this.classList.add('filter-open');
                filterContent.style.display = 'block';
                filterContent.setAttribute('aria-hidden', false);
            } else {
                this.classList.remove('filter-open');
                filterContent.style.display = 'none';
                filterContent.setAttribute('aria-hidden', true);
            }
        }, {passive: true});
    }

    // add change listener for the multiselect that update their filterlist
    var nativeSelectFilter = queryAll('.native-select-filter');
    for (let i = 0; i < nativeSelectFilter.length; i++) {
        nativeSelectFilter[i].addEventListener('change', function () {
            const options = this.querySelectorAll('option');
            const selected = getSelectedFromNodeList(options);

            console.log(selected);
            updateFilterListFromSelect(selected, querySelect(this.dataset.for));
        });
    }

    // add listeners for the media queries
    desktopXlMQ.addListener(updateHeight);
    desktopMQ.addListener(updateHeight);
    tabletMQ.addListener(updateHeight);
    mobileMQ.addListener(updateHeight);

    // build an object containing all filter elements and the values needed for filtering
    const filterListsObj = {};
    const filterListWrappers = queryAll('.filter-list-wrapper');
    for (let flw = 0; flw < filterListWrappers.length; flw++) {
        const fwel = filterListWrappers[flw];
        const filter = replaceSpecialChars(fwel.getAttribute('data-filter-type'));
        const inputs = fwel.querySelectorAll('input');
        const listObj = {
            listEl: fwel,
            inputArr: []
        };

        for (let i = 0; i < inputs.length; i++) {
            listObj.inputArr.push({
                key: filter,
                id: inputs[i].value,
                urlname: inputs[i].getAttribute('data-url'),
                element: inputs[i],
                index: i
            });
        }
        filterListsObj[filter] = listObj;
    }

    // update item heights to make the equal on tablet view and higher
    function updateHeight () {
        const items = queryAll('.movie-row-active .movie-item');
        const heights = [];
        let maxHeight;

        // if not mobile view get highest element
        if (window.matchMedia('(max-width: 767px)').matches === false) {
            for (let i = 0; i < items.length; i++) {
                if (items[i].classList.contains('empty-movie-item')) {
                    continue;
                }

                items[i].style.height = 'auto';
                heights.push(items[i].getBoundingClientRect().height);
            }

            maxHeight = getMaxOfArray(heights);
        }

        for (let j = 0; j < items.length; j++) {
            if (items[j].classList.contains('empty-movie-item')) {
                continue;
            }

            // if not mobile view set new px height else set it to auto
            if (window.matchMedia('(max-width: 767px)').matches === false) {
                items[j].style.height = parseInt(maxHeight)+'px';
            } else {
                items[j].style.height = 'auto';
            }
        }
    }

    // only initiate Shuffle after images have loaded
    new imagesLoaded('.movies-row', function () {
        requestAnimationFrame(() => {
            updateHeight();
        });
        requestAnimationFrame(() => {
            const url = createFilterUrl(initialOrPushParemeters);
            const movieRows = queryAll('.movies-row');
            const activeRow = parseInt(querySelect('.movie-row-active').getAttribute('data-index')) || 0;

            // create an array out of the urls
            const filterUrlArr = url.path.length > 0 ? url.path.split('+') : [];

            // check active filter inputs
            for (let key in initialOrPushParemeters) {
                if (filterListsObj.hasOwnProperty(key)) {
                    let obj = filterListsObj[key].inputArr;
                    let queryStrings = initialOrPushParemeters[key].split('+');
                    // loop trough input fields
                    for (let i = 0; i < obj.length; i++) {
                        // set false to make sure that really only active fields are checked
                        obj[i].element.checked = false;
                        // curret urlname found in query strings
                        if (queryStrings.indexOf(obj[i].urlname) !== -1) {
                            obj[i].element.checked = true;
                        }
                    }
                }
            }

            for (let i = 0; i < movieRows.length; i++) {
                if (i === activeRow) {
                    shuffleInstances[i] = new Shuffle(movieRows[activeRow], shuffleOptionsDefault);
                    intersectedFilter(shuffleInstances[i], filterUrlArr, url.count);
                } else {
                    shuffleInstances[i] = {};
                }
            }
        });
    });

    // add change listeners to all filter inputs
    const filterInputs = queryAll('.filter-content input');
    for (let fi = 0; fi < filterInputs.length; fi++) {
        filterInputs[fi].addEventListener('change', function () {
            const filters = getActiveFilters(filterListsObj);
            const url = createFilterUrl(filters);
            const index = parseInt(querySelect('.movie-row-active').getAttribute('data-index'));
            // create an array out of the urls
            const filterUrlArr = url.path.length > 0 ? url.path.split('+') : [];

            // update url
            if ('history' in window) {
                updatePushUrl(filters);
                initialOrPushParemeters = getUrlParameters();
            }

            intersectedFilter(shuffleInstances[index], filterUrlArr, url.count);
        });
    }

    // add change listeners to all filter inputs
    const filterReviews = queryAll('.filter-review input');
    for (let fr = 0; fr < filterReviews.length; fr++) {
        filterReviews[fr].addEventListener('change', function () {
            const filters = getActiveFilters(filterListsObj);
            const url = createFilterUrl(filters);
            const index = parseInt(querySelect('.movie-row-active').getAttribute('data-index'));
            const filterUrlArr = url.path.length > 0 ? url.path.split('+') : [];

            // update url
            if ('history' in window) {
                updatePushUrl(filters);
                initialOrPushParemeters = getUrlParameters();
            }

            intersectedFilter(shuffleInstances[index], filterUrlArr, url.count);
        });
    }


    const rowDayActiveClass = 'movie-row-active';
    const filterWeeks = queryAll('.movie-nav-week-list input');
    for (let fw = 0; fw < filterWeeks.length; fw++) {
        filterWeeks[fw].addEventListener('change', function () {
            this.checked = true;
            const filters = getActiveFilters(filterListsObj);
            const url = createFilterUrl(filters);
            const day = querySelect(this.value);
            const index = parseInt(this.getAttribute('data-index'));
            const filterUrlArr = url.path.length > 0 ? url.path.split('+') : [];

            querySelect('.movie-row-active').classList.remove(rowDayActiveClass);
            day.classList.add(rowDayActiveClass);

            // update url
            if ('history' in window) {
                updatePushUrl(filters);
                initialOrPushParemeters = getUrlParameters();
            }

            // get shuffle reference from instances pool
            const shuffleFilter = shuffleInstances[index];

            // if shuffle was initialised
            if (shuffleFilter instanceof Shuffle) {
                intersectedFilter(shuffleFilter, filterUrlArr, url.count);
            } else {
                requestAnimationFrame(() => {
                    updateHeight();
                });
                // shuffle not instantiated for row, instantiate it
                requestAnimationFrame(() => {
                    shuffleInstances[index] = new Shuffle(day, shuffleOptionsDefault);
                    intersectedFilter(shuffleInstances[index], filterUrlArr, url.count);
                });
            }
        });
    }

    const filterListInputs = queryAll('.filter-content input');
    window.onpopstate = (event) => {
        let parameters = getUrlParameters();

        // Workaround for the shitty ios back/forward button cache.
        // Fires popstate otherwise, which reloads the ajax content and
        // thus scrolls view back to top
        if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
            if (isEqual(initialOrPushParemeters,parameters)) {
                return false;
            }
        }

        let matchingFilters = {};
        // build an object of matching filters
        for (let key in parameters) {
            if (filterListsObj.hasOwnProperty(key)) {
                let obj = filterListsObj[key].inputArr;
                // create array out of parameter urls
                let queryStrings = parameters[key].split('+');
                // loop trough input fields
                for (let i = 0; i < obj.length; i++) {
                    const co = obj[i];
                    // set false to make sure that really only active fields are checked
                    co.element.checked = false;
                    // curret urlname found in query strings
                    if (queryStrings.indexOf(co.urlname) !== -1) {
                        if (key === 'tag') {
                            const day = querySelect(co.element.value);
                            querySelect('.movie-row-active').classList.remove(rowDayActiveClass);
                            day.classList.add(rowDayActiveClass);
                        }

                        // if multiple filters are active, concat urls via +
                        if (matchingFilters.hasOwnProperty(key)) {
                            matchingFilters[key].urlname += `+${co.urlname}`;
                        } else {
                            matchingFilters[key] = {
                                urlname: co.urlname
                            };
                        }
                        // set input active
                        co.element.checked = true;
                    }
                }
            }
        }

        // get object key diff between last params and new params
        const diff = difference(Object.keys(initialOrPushParemeters),Object.keys(parameters));

        // loop through now unactive list and reset all inputs
        for (let i = 0; i < diff.length; i++) {
            // found removed object
            if (filterListsObj.hasOwnProperty(diff[i])) {
                let obj = filterListsObj[diff[i]].inputArr;
                for (let j = 0; j < obj.length; j++) {
                    obj[j].element.checked = false;
                }
            }
        }

        // if no query params left uncheck all filter inputs
        if (window.location.search === '') {
            for (let i = 0; i < filterListInputs.length; i++) {
                filterListInputs[i].checked = false;
            }

            if (filterWeeks.length > 0) {
                // show first movies row
                querySelect('.movie-row-active').classList.remove(rowDayActiveClass);
                querySelect('.movies-row').classList.add(rowDayActiveClass);
                querySelect('.nav-week-link').checked = true;
            }
        }

        const url = createFilterUrl(matchingFilters);
        const filterUrlArr = url.path.length > 0 ? url.path.split('+') : [];
        const day = querySelect('.movie-row-active');
        const index = parseInt(day.getAttribute('data-index'));

        // get shuffle reference from instances pool
        const shuffleFilter = shuffleInstances[index];

        // if shuffle was initialised
        if (shuffleFilter instanceof Shuffle) {
            requestAnimationFrame(() => {
                intersectedFilter(shuffleFilter, filterUrlArr, url.count);
            });
        } else {
            requestAnimationFrame(() => {
                updateHeight();
            });
            // shuffle not instantiated for row, instantiate it
            requestAnimationFrame(() => {
                shuffleInstances[index] = new Shuffle(day, shuffleOptionsDefault);
                intersectedFilter(shuffleInstances[index], filterUrlArr, url.count);
            });
        }

        // update params object
        initialOrPushParemeters = parameters;
    };
};