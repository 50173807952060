'use-strict';

import {intersection} from '../vendor';

export const replaceSpecialChars = (text) => {
    return text.replace(/[éè]/g, 'e').replace(/[âà]/g, 'a').replace(/[ç]/g, 'c')
        .replace(/[ö]/g, 'oe').replace(/[ä]/g, 'ae').replace(/[ü]/g, 'ue').replace(/[^\w]/g, '-');
};

export const getActiveFilters = (filterLists) => {
    if (typeof filterLists !== 'object') {
        console.error('No filter list provided');
    }

    let activeFilters = {};
    for (const key in filterLists) {
        let active = '';
        let labels = '';
        let index = 0;
        if (filterLists.hasOwnProperty(key)) {
            const obj = filterLists[key].inputArr;
            // loop trough input fields
            for (let i = 0; i < obj.length; i++) {
                const o = obj[i];
                if (o.element.checked) {
                    const label = encodeURIComponent(o.element.getAttribute('data-url')).toLowerCase();
                    active += active === '' ? o.element.value : ',' + o.element.value; // add a comma if multiple
                    labels += labels === '' ? label : '+'+label; // add a plus if multiple
                    index = o.index;
                }
            }

            // update active filter counter
            // var activeCount = active.split(',').length;
            // $('#'+filterLists[key].listEl.getAttribute('data-count')).text(active === '' ? '' : '('+activeCount+')');

            const inputName = replaceSpecialChars(filterLists[key].listEl.getAttribute('data-filter-type'));
            activeFilters[inputName] = {
                key: inputName,
                id: active,
                urlname: labels,
                index: index
            };
        }
    }

    return activeFilters;
};

/**
 * Create an url to create the filter array
 * @param {object} filterObj An object of filters to get the urls from
 * @returns {string} ulrs
 */
export const createFilterUrl = (filterObj) => {
    let urls = '';
    let count = 0; // filter types

    // collect urls from active filter list elements
    for (const key in filterObj) {
        if (key === 'tag') {
            continue;
        }

        // if no urls set jump to next iteration
        if (typeof filterObj[key] === 'object' && filterObj[key].urlname === '' || filterObj[key] === '') {
            continue;
        }

        // if there are multiple filters, add + as a seperator
        if (count > 0) {
            urls += '+';
        }

        urls += typeof filterObj[key] === 'object' ? filterObj[key].urlname : filterObj[key];
        count++;
    }

    return {path: urls, count: count};
};

export const intersectedFilter = (instance, filterUrlArr, filterCount) => {
    let foundElements = false;
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    // we have active filters
    if (filterCount > 0) {
        instance.filter(function (element) {
            const categories = JSON.parse(element.getAttribute('data-groups'));
            const cinemas = JSON.parse(element.getAttribute('data-cinemas'));
            const reviews = JSON.parse(element.getAttribute('data-review'));
            let filterApplies = false;

            if (categories.indexOf('empty') !== -1) {
                element.classList.add('hidden-xs-up');
            }

            // check if on daily view or not
            if (instance.element.classList.contains('movie-daily-row')) {
                if (filterCount === 2) {
                    // check if element matches at least one filter for both types
                    if (intersection(filterUrlArr, categories).length > 0 && intersection(filterUrlArr, cinemas).length > 0) {
                        filterApplies = true;
                    }
                } else {
                    // check if element matches at least one filter from one of the types
                    if (intersection(filterUrlArr, categories).length > 0 || intersection(filterUrlArr, cinemas).length > 0 || intersection(filterUrlArr, reviews).length > 0) {
                        filterApplies = true;
                    }
                }
            } else {
                if (filterCount === 2) {
                    // check if element matches at least one filter for both types
                    if (intersection(filterUrlArr, categories).length > 0 && intersection(filterUrlArr, reviews).length > 0) {
                        filterApplies = true;
                    }
                } else {
                    // check if element matches at least one filter from one of the types
                    if (intersection(filterUrlArr, categories).length > 0 || intersection(filterUrlArr, reviews).length > 0) {
                        filterApplies = true;
                    }
                }
            }

            if (filterApplies === true) {
                foundElements = true;
            }

            return filterApplies;
        });
    } else {
        // no active filter, show all and hide empty item
        instance.filter(function (element) {
            const categories = JSON.parse(element.getAttribute('data-groups'));

            // current item is the empty
            if (categories.indexOf('empty') !== -1) {
                // show it if it's the only item, else hide it
                if (instance.items.length === 1) {
                    element.classList.remove('hidden-xs-up');
                    return true;
                } else {
                    element.classList.add('hidden-xs-up');
                    return false;
                }
            } else {
                // show all items
                return true;
            }
        });

        // iOS doesn't layout correctly the first time
        if (iOS === true) {
            setTimeout(() => {
                instance.layout();
            }, 120);
        }

        foundElements = true;
    }

    if (!foundElements) {
        requestAnimationFrame(() => {
            instance.filter(function (element) {
                const categories = JSON.parse(element.getAttribute('data-groups'));

                if (categories.indexOf('empty') !== -1) {
                    element.classList.remove('hidden-xs-up');
                    return true;
                } else {
                    return false;
                }
            });
        });
    }
};