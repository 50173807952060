'use-strict';

// Handle modal activation
export const modal = (selector) => {
    const modalWrapperElement = document.querySelector(selector);
    const closeEvent = new Event('modal:closed');

    if (modalWrapperElement === null) {
        console.warn('No element for selector "'+selector+'" found');
        return false;
    }
    const activeClass = 'modal-active';
    const closeBtn = modalWrapperElement.querySelector('.close-modal');
    const body = document.body;

    const closeModal = () => {
        body.classList.remove('noscroll');
        modalWrapperElement.setAttribute('aria-hidden', 'true');
        modalWrapperElement.classList.remove(activeClass);
        modalWrapperElement.dispatchEvent(closeEvent);
    };

    // add click listener to close button
    closeBtn.addEventListener('click', closeModal, {passive: true});
    modalWrapperElement.addEventListener('click', function (event) {
        if (event.target === modalWrapperElement) {
            closeModal();
        }
    }, {passive: true});

    return {
        open () {
            body.classList.add('noscroll');
            modalWrapperElement.classList.add(activeClass);
            modalWrapperElement.setAttribute('aria-hidden', 'false');
        },
        close: closeModal
    };
};