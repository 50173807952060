'use-strict';

import {querySelect} from '../helpers/dom';

export const share = () => {
    const fbshare = querySelect('.share-to-facebook');
    if (fbshare !== null) {
        fbshare.addEventListener('click', function (e) {
            e.preventDefault();

            FB.ui({
                method: 'share',
                display: 'popup',
                href: window.location.href
            }, function(response){});
        });
    }

    const washare = querySelect('.share-to-whatsapp');
    if (washare !== null) {
        washare.addEventListener('click', function (e) {
            e.preventDefault();

            const shareUrl = encodeURIComponent(window.location.href);
            const waBody = encodeURIComponent(this.getAttribute('data-body'));
            const shareContent = `${waBody}%0A%0A${shareUrl}`;

            window.open('whatsapp://send?text=' + shareContent);
        });
    }
};