'use-strict';

import '../plugins/mediaelement/quality';
import {querySelect, queryAll} from '../helpers/dom.js';
import {modal} from './modal.js';
// import {player} from './videoPlayer';

export const modalPlayer = function () {
    const moviePlayTrailer = queryAll('.js-play-trailer');
    const TrailerModal = modal('.movie-trailer-modal');
    const trailerModalWrapper = querySelect('.movie-trailer-modal');
    const modalVideo = querySelect('#modalPlayer');
    const embed = trailerModalWrapper.querySelector('.embed-responsive-item');

    if (modalVideo) {
        let player = null;
        // let initPlayer = Player.init();

        for (let i = 0; i < moviePlayTrailer.length; i++) {
            moviePlayTrailer[i].addEventListener('click', function () {
                const videoUrl = JSON.parse(this.dataset.url);
                const playerEl = querySelect('#modalPlayer');

                const sources = playerEl.children;
                let src;
                // if (videoUrl.high.indexOf('youtube.com') !== -1 || videoUrl.low.indexOf('youtube.com') !== -1) {
                //     src = videoUrl.high !== '' ? videoUrl.high : videoUrl.low;
                // } else {
                    // update source elements src
                    for (var i = 0; i < sources.length; i++) {
                        var el = sources[i];
                        if (el.dataset.quality === 'HD') {
                            el.src = videoUrl.high;
                        } else {
                            el.src = videoUrl.low;
                        }
                    }

                    src = [{ src: videoUrl.low, type: 'video/mp4' }, { src: videoUrl.high, type: 'video/mp4' }];
                // }

                setTimeout(() => {
                    player = new MediaElementPlayer(playerEl, {
                        stretching: 'fill',
                        features: ['playpause', 'current', 'progress', 'duration', 'tracks', 'volume', 'fullscreen', 'quality'],
                        src: src,
                        defaultQuality: 'SD',
                        qualityText: 'Video Qualität',
                        success: function (mediaElement, originalNode, instance) {
                            TrailerModal.open();
                            setTimeout(() => {
                                instance.play();
                            }, 300);
                        }
                    });
                }, 200);
            }, {passive: true});
        }

        trailerModalWrapper.addEventListener('modal:closed', function () {
            if (player !== null) {
                player.pause();
            }

            embed.textContent = '';
            const newVideo = document.createElement('video');
            newVideo.innerHTML = '<source type="video/mp4" src="" data-quality="HD" /><source type="video/mp4" src="" data-quality="SD" />';
            newVideo.id = 'modalPlayer';
            embed.appendChild(newVideo);
        });
    }
};